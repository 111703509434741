import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Header, RelatedPosts, TransitionMask } from '@components/organisms'
import { CapsuleButton } from '@components/atoms'
import { Grid, GridItem } from '@components/grid'
import { WithData } from '@utils'
import { ProjectProps } from '@types'
import { SimplePostProps } from '@components/organisms/RelatedPosts'


const ProjectTemplate = ({ 
  pageContext: {
    settings,
  },
  data: {
    sanityPost: { 
      slug,
      title, 
      description,
      shareImage,
      gridItems,
    },
    sanityPage
  },
}: ProjectProps): React.ReactElement => {
  const projectSettings = {
    ...settings,
    subtitle: title,
    shareImage: shareImage || settings.shareImage,
    description: description || settings.description,
  }
  
  const parsedTitle = 'For Good Measure: ' + title
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
    { property: "og:image", content:  `${shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
    { name: "twitter:image", content:  `${shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
  ]

  let previousPost: SimplePostProps | undefined = undefined
  let nextPost    : SimplePostProps | undefined = undefined
  const filteredNodes = sanityPage.gridItems.filter(node => node.post && node.post.slug)
  filteredNodes.map((node: any, i: number) => {
    if( node.post.slug.current === slug.current ) {
      previousPost = (i === 0) ? filteredNodes[filteredNodes.length - 1] : filteredNodes[i - 1]
      nextPost = (i === filteredNodes.length - 1) ? filteredNodes[0] : filteredNodes[i + 1]
    }
  })
  
  return (
    <div>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>
      
      <Header />
      <StyledGrid>
        {gridItems.filter(p => !!p).map((item) => (
          <GridItem 
            key={item._key} 
            {...item} 
          />
        ))}
        <RelatedPosts {...{ previousPost, nextPost, }} />
        <BackButton>
          <CapsuleButton to="/work" >Back to all work</CapsuleButton>
        </BackButton>
      </StyledGrid>
      
      <TransitionMask />
    </div>
  )
}


const StyledGrid = styled(props => <Grid {...props} />)`
  margin-top: 75px;
  @media only screen and (min-width: 768px) {
    margin-top: 90px;
  }
`


const BackButton = styled.div`
  grid-column-end: span 1;
  @media only screen and (min-width: 768px) {
    grid-column-end: span 6;
  }
  text-align: center;
  margin: 4em auto;
`


const mapDataToProps = ({ sanityPost }: any) => ({ ...sanityPost })


export const query = graphql`
  query project($slug: String!) {
    sanityPost(slug: {current: {eq: $slug}}) {
      slug {
        current
      }
      title
      description
      shareImage {
        asset {
          url
        }
      }
      gridItems {
        ... on SanityGridItem {
          ...gridItemFields
        }
      }
    }
    sanityPage(slug: {current: {eq: "work"}}) {
      gridItems {
        ... on SanityGridItem {
          gridItemTypeSwitch {
            gridItemType
            gridItemImage {
              image {
                asset {
                  url
                  metadata {
                    dimensions {
                      width
                      height
                      aspectRatio
                    }
                  }
                }
              }
            }
            gridItemVideo {
              video {
                asset {
                  url
                }
              }
            }
          }
          post {
            slug {
              current
            }
            title
            description
            year
            paddedBackgroundColor {
              hex
            }
          }
        }
      }
    }
  }
`

export default WithData(mapDataToProps, ProjectTemplate)